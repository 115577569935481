/* autoprefixer grid: autoplace */
import { graphql } from "gatsby";
import React, { useState } from "react";

import { Routes } from "../constants/routes";
import Layout from "../components/layout-main";
import { trackButtonCta } from "../components/analytics";
import { scrollToElm, checkIfIE } from "../components/dom";
import { ContactForm, FormTypes } from "../components/form-contact";
import CalendlySection, {
  CalendlyEventTypes,
} from "../components/form-calendly";
import Testimonials from "../components/testimonials";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import Img from "gatsby-image";

/** @jsx jsx */
import { jsx, Grid, Flex, Button, Link, NavLink, Styled } from "theme-ui";
import { HowItWorksWhatNow } from "../components/lp/how-it-works";
import { BEREAVEMENT_GUIDE_QUESTIONS, Faqs } from "../components/faqs";
import { Helmet } from "react-helmet";

const BereavementContact = ({ hideCalendly }) => {
  return (
    <ContactForm
      buttonVariant="blueButton"
      formType={FormTypes.HOSPITAL_BEREAVEMENT}
      formTitle=""
      formOutro="Thanks for signing up. A teammate will reach out to you shortly."
      hideImage={true}
      buttonText="Sign up for Free"
      calendlyType={!hideCalendly && CalendlyEventTypes.HOSPITAL_BEREAVEMENT}
    />
  );
};

const FeaturePanel = (props) => {
  const isMobile = !props.laptop;
  const width = isMobile ? 250 : 700;
  const maxWidth = isMobile ? "250px" : "100%";
  return (
    <div
      key={props.key}
      sx={{
        maxWidth: isMobile ? "690px" : "100%",
        margin: "0 auto",
        marginTop: "48px",
        paddingBottom: ["24px", 0],
        borderBottom: "1px solid",
        borderColor: ["rgba(0, 0, 0, 0.35)", "transparent"],
      }}
    >
      <Grid
        gap={isMobile ? ["12px", "64px"] : ["12px", "24px"]}
        columns={isMobile ? ["1fr", "2fr 3fr"] : ["1fr", "3fr 1fr"]}
        sx={{
          transition: "transform .35s ease-in-out",
          alignItems: "center",
          display: isMobile ? "grid" : "flex",
          flexDirection: "row-reverse",
        }}
      >
        <div>
          {props.imgRef && (
            <Img
              title="Afterword app logo"
              alt="Afterword app logo"
              sx={{
                width: ["50%", "100%"],
                margin: ["0 auto"],
              }}
              fluid={props.imgRef}
            />
          )}
          {props.vidRef && (
            <div
              sx={{
                margin: ["0 auto", "auto"],
                position: "relative",
                maxWidth: maxWidth,
                height: "506px",
                overflow: "hidden",
                pointerEvents: "none",
              }}
            >
              <img
                src={isMobile ? "/images/iphone-12.png" : "/images/mbp.png"}
                sx={{
                  maxWidth: maxWidth,
                  top: 0,
                  position: "absolute",
                  zIndex: 2,
                }}
              />
              <video
                preload
                autoPlay
                playsInline
                webkit-playsinline
                muted
                loop={true}
                sx={{
                  position: "relative",
                  top: 0,
                  zIndex: 1,
                  maxWidth: width - 100,
                  padding: isMobile ? "0 12px 4px 12px" : "40px 64px 24px 24px",
                  borderRadius: "30px",
                  marginTop: "4px",
                  marginLeft: isMobile ? 0 : "36px",
                }}
              >
                <source type="video/mp4" src={props.vidRef} />
              </video>
            </div>
          )}
        </div>
        <div>
          <h2 sx={{ transition: "transform .35s ease-in-out", marginTop: 0 }}>
            {props.title}
          </h2>
          <p sx={{ transition: "transform .35s ease-in-out" }}>{props.blurb}</p>
          <a
            href="https://app.afterword.com/?ht=demo&utm_source=lp"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="blueButton"
              onClick={() => {
                trackButtonCta("whatnow-view-demo");
              }}
            >
              View Demo
            </Button>
          </a>
        </div>
      </Grid>
    </div>
  );
};

export const BereavementFeatures = ({ data, features, laptop }) => {
  const titles = features || [
    {
      title: "Language & Cultural Support",
      tag: "Multiple Languages",
      // imgRef: data.tool2.childImageSharp.fluid,
      vidRef: "/images/tool2.mp4",
      blurb:
        "We translate all content, including your bereavement resources, to Spanish, Chinese, and a growing list of other languages. We also offer curated guidance based on cultural and spiritual beliefs.",
    },
    {
      title: "Individualized Grief Resources",
      tag: "Individualized Support",
      // imgRef: data.tool4.childImageSharp.fluid,
      vidRef: "/images/tool4.mp4",
      blurb:
        "We've created a database of over 1,000+ resources across the country. Families can find relevant resources for each member based on their relationship, age, and preferences. Combined with your hospital bereavement groups, we personalize the recommendations for each individual because grief is unique.",
    },
    {
      title: "Comprehensive Funeral Guidance",
      tag: "Funeral Guidance",
      // imgRef: data.tool3.childImageSharp.fluid,
      vidRef: "/images/tool3.mp4",
      blurb:
        "Through immersive video content, we walk families through their funeral options. Based on what’s important to them—religious beliefs, sustainability, personal preferences—we guide them through every step in the process. We offer individualized guidance so they feel confident about their decisions.",
    },
    {
      title: "Automatically Updated",
      tag: "Automatically Updated",
      // imgRef: data.tool1.childImageSharp.fluid,
      blurb:
        "We combine our comprehensive resources with your bereavement guide and automatically keep the resources up-to-date. If a funeral home changes their phone number, if a local grief support group updates their schedule, if a bereavement book becomes a National bestseller—we’ve got you covered.",
      vidRef: "/images/tool1-2.mp4",
    },
    {
      title: "Available Wherever You Are",
      tag: "Easily Accessible",
      imgRef: data?.tool1?.childImageSharp?.fluid,
      blurb:
        "There's no app download needed. Families can use the tool from their phone, tablet, or laptop—any device that's connected to the internet. ",
    },
  ];
  const [panelVisible, setPanelVisible] = useState(true);
  const [value, setValue] = useState(titles[0]);
  const breakpointIndex = useBreakpointIndex();

  return (
    <>
      {breakpointIndex > 0 && (
        <div
          sx={{
            display: "flex",
            justifyContent: ["space-around", "space-around", "space-between"],
            // border: "2px solid #EEEEEE",
            // borderRadius: "30px",
            flexWrap: "wrap",
            fontWeight: 500,
            gridTemplateColumns: titles.map((_) => "1fr").join(" "),
            borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
          }}
        >
          {titles.map((section, idx) => {
            let isSelected = section.title === value.title;
            return (
              <p
                key={idx}
                sx={{
                  // borderRadius: "30px",
                  // margin: "4px",
                  textAlign: "center",
                  height: "46px",
                  padding: 0,
                  margin: 0,
                  lineHeight: "46px",
                  color: isSelected ? "black" : "rgba(48, 48, 49, 0.65)",
                  borderBottom: "2px solid",
                  borderColor: isSelected ? "black" : "transparent",
                  // backgroundColor: isSelected ? "navy" : "inherit",
                  // color: isSelected ? "white" : "inherit",
                  "&:hover": {
                    cursor: "pointer",
                    // textDecoration: !isSelected ? "underline" : "none",
                    color: "black",
                    borderColor: "black",
                  },
                  verticalAlign: "middle",
                }}
                onClick={() => {
                  setValue(section);
                }}
              >
                {section.tag}
              </p>
            );
          })}
        </div>
      )}
      {breakpointIndex > 0 && (
        <div
          sx={{
            "& .example-enter": {
              opacity: 0.01,
            },
            "& .example-enter.example-enter-active": {
              opacity: 1,
              transition: "opacity 200ms ease-in-out",
            },
            "& .example-exit": {
              opacity: 1,
            },
            "& .example-exit.example-exit-active": {
              opacity: 0.01,
              transition: "opacity 200ms ease-in-out",
            },
          }}
        >
          <SwitchTransition mode={"out-in"}>
            <CSSTransition
              key={value.title}
              in={panelVisible}
              timeout={300}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="example"
            >
              <FeaturePanel
                key={value.title}
                imgRef={value.imgRef}
                vidRef={value.vidRef}
                title={value.title}
                blurb={value.blurb}
                laptop={laptop}
              />
            </CSSTransition>
          </SwitchTransition>
        </div>
      )}

      {breakpointIndex === 0 && (
        <div sx={{ textAlign: "center" }}>
          {titles.map((section) => {
            return (
              <FeaturePanel
                imgRef={section.imgRef}
                vidRef={section.vidRef}
                title={section.title}
                blurb={section.blurb}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export const NonVirtualTestimonialReviews = [
  {
    review:
      "Effie of Afterword does fantastic work and is a pleasure to work with, particularly during such a stressful time. She and her team make it look easy. I cannot recommend Effie and the Afterword team highly enough.",
    author: "Ron F.",
  },
  {
    review:
      "Afterword is an incredible company. Zack and Effie bring mounds of empathy to the work they do, which is so critical to helping families during their most emotional times. When my mom passed away, we were devastated and we wanted to honor her legacy the best we could. Afterword helped us achieve our goal in a remarkable way. ",
    author: "Ben A.",
  },
  {
    review:
      "Thank you Afterword, and Kiersten in particular, for helping to make the tribute to our mother so special. We appreciate you and will remember you forever.",
    author: "Susan A.",
  },
  {
    review:
      "Words can’t express how grateful we are to the Afterword team . From the time we reached out to them until the time they delivered the final links, the entire Afterword team over delivered.  What a great service and keepsake . My family cannot thank you enough for helping us out during this trying time.",
    author: "Paolo V.",
  },
  {
    review:
      "Afterword is an amazing service that made the memorial service planning for my Dad substantially easier.  Would give the service 11 stars if I could. Afterword is really providing humanity with a much needed service, especially during these pandemic times.  I'm so happy I went with them!",
    author: "Jeff C.",
  },
  {
    review:
      "The Afterword team were incredible to work with. Responsive, compassionate, helpful and thoughtful from the very start. They worked seamlessly and professionally with the venue. I really can’t thank them enough for supporting us through this time.",
    author: "Amy B.",
  },
];

const BereavementPage = ({ data, location }) => {
  const breakpointIndex = useBreakpointIndex();

  let navLinksOverride = () => {
    return (
      <>
        <NavLink
          tabIndex="0"
          href="#features"
          onClick={(event) => {
            event.preventDefault();
            scrollToElm("features");
          }}
        >
          Features
        </NavLink>
        <NavLink
          tabIndex="0"
          href="#aboutus"
          onClick={(event) => {
            event.preventDefault();
            scrollToElm("aboutus");
          }}
        >
          About
        </NavLink>
        <NavLink
          tabIndex="0"
          href="#howitworks"
          onClick={(event) => {
            event.preventDefault();
            scrollToElm("howitworks");
          }}
        >
          How it Works
        </NavLink>
        <NavLink
          tabIndex="0"
          href="#faqs"
          onClick={(event) => {
            event.preventDefault();
            scrollToElm("faqs");
          }}
        >
          FAQs
        </NavLink>
        <NavLink
          tabIndex="0"
          href="#testimonials"
          onClick={(event) => {
            event.preventDefault();
            scrollToElm("testimonials");
          }}
        >
          Testimonials
        </NavLink>
      </>
    );
  };

  return (
    <Layout
      location={location}
      pageTitle="Comprehensive Bereavement Guides, Translated & Hosted for Free"
      hideSubscribe={true}
      hideLinks={true}
      hideNonCompanyLinks={true}
      noIndex={true}
      navLinksOverride={navLinksOverride()}
      buttonOverride={"#signup"}
      variant={"health"}
      buttonOverrideClickHandler={(event) => {
        scrollToElm("form");
        let elm = document.querySelector("#form input[type=email]");
        elm && elm.focus();
      }}
    >
      <section
        sx={{
          backgroundColor: "beige",
          backgroundImage: "url(/images/header-splash.png)",
          backgroundPosition: "bottom",
          paddingTop: "3rem",
          paddingBottom: 0,
          backgroundSize: ["100%", "100%"],
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="aw-content">
          <Grid
            gap={[1, 0]}
            columns={["auto", "3fr 2fr"]}
            sx={{ alignItems: ["center", "flex-start"] }}
          >
            <div sx={{ gridRow: [2, 1] }}>
              <Styled.h2
                sx={{
                  fontWeight: "300",
                  fontSize: ["30px", "30px", "36px"],
                  marginBottom: 0,
                  lineHeight: "133% !important",
                  letterSpacing: "0.01em",
                  fontFamily: "Georgia",
                }}
              >
                Comprehensive bereavement guides, translated & hosted for free
              </Styled.h2>
              <p
                sx={{
                  fontSize: "20px",
                  color: "#66645E",
                  letterSpacing: "-0.02em",
                  lineHeight: "133%",
                  marginTop: ["12px", "16px", "24px"],
                  marginBottom: 0,
                  maxWidth: "520px",
                  fontWeight: 400,
                  fontFamily: "Avenir",
                }}
              >
                Afterword helps healthcare organizations across the US support
                grieving families. We offer an all-encompassing support guide
                for making important decisions on funeral planning & grief
                resources.
              </p>
              <div
                sx={{
                  gridRow: [1, 2],
                  marginTop: "12px",
                  marginBottom: "24px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                  width="20px"
                  height="20px"
                />
                <img
                  src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                  width="20px"
                  height="20px"
                />
                <img
                  src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                  width="20px"
                  height="20px"
                />
                <img
                  src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                  width="20px"
                  height="20px"
                />
                <img
                  src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                  width="20px"
                  height="20px"
                />
                <p
                  sx={{
                    fontSize: "12px",
                    lineHeight: "24px",
                    margin: "0 0 0 8px",
                  }}
                >
                  <Link
                    href={`https://www.google.com/search?ved=1t:65428&hl=en&q=Afterword&ludocid=6984910543917685674&lsig=AB86z5UegKdeFVGPjdSMcp2ZMO8A#lrd=0x808f7f98ee054a9d:0x60ef6321ce181baa,1,,,`}
                    target="_blank"
                  >
                    Rated 5.0 stars
                  </Link>{" "}
                  & trusted by hundreds of families
                </p>
              </div>
              <div
                sx={{
                  maxWidth: "400px",
                }}
              >
                <BereavementContact hideCalendly={true} />
              </div>
            </div>

            <div sx={{ marginBottom: [0, "12px"] }}>
              <Img
                title="Afterword app logo"
                alt="Afterword app logo"
                sx={{
                  width: ["50%", "100%"],
                  margin: ["0 auto"],
                }}
                fluid={data.home.childImageSharp.fluid}
              />
            </div>
          </Grid>
        </div>
      </section>

      <section id="features" sx={{ paddingTop: "3rem" }}>
        <div class="aw-content">
          <BereavementFeatures data={data} />
        </div>
      </section>

      <section>
        <div className="aw-content" sx={{ textAlign: "center" }}>
          <h2 sx={{ marginBottom: "48px" }}>
            What Families can do with Afterword
          </h2>
          <Grid gap={3} columns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}>
            {[
              {
                img: "/images/Resources.png",
                blurb: "Join Local Grief Support Groups",
              },
              // {img: "/images/shoulderlean.png", blurb: "Speak with our Dedicated Support Team", props: { borderRadius: "110px", height: "170px", width: "170px", margin: "0 auto" }},
              {
                img: "/images/Explore-Religions.png",
                blurb: "Learn about Religious Traditions",
              },
              {
                img: "/images/Burial-Cremation.png",
                blurb: "Understand their Funeral Options",
              },
              // {img: "/images/Hands-Photos.png", blurb: "Share Memorial Moments"},
              {
                img: "/images/Venues-dark.png",
                blurb: "Compare Local Funeral Homes",
              },
              {
                img: "/images/Service.png",
                blurb: "Plan a Funeral or Memorial",
              },
              {
                img: "/images/Hands-Photos.png",
                blurb: "Find Comforting Books & Podcasts",
                props: { maxWidth: "150px", margin: "0 auto" },
              },
            ].map((section) => {
              return (
                <Grid
                  sx={{
                    gridTemplateRows: ["170px auto"],
                    alignItems: "center",
                  }}
                >
                  <img
                    src={section.img}
                    sx={{
                      maxWidth: "120px",
                      width: "100%",
                      margin: "0 auto",
                      ...section.props,
                    }}
                  />
                  <p sx={{ fontWeight: 500, fontSize: "1.15rem" }}>
                    {section.blurb}
                  </p>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </section>

      <section className="aw-accent">
        <div className="aw-content">
          <Styled.h2
            sx={{
              fontWeight: "600",
              fontSize: ["24px", "24px", "30px"],
            }}
          >
            A death changes life forever—we're here for
            <br /> everything after
          </Styled.h2>
        </div>
      </section>

      <section>
        <div className="aw-content" sx={{ textAlign: "center" }}>
          <h4 sx={{ fontSize: "24px", fontWeight: 500 }}>
            Piloting in world-class hospital systems
            <br />
            across the country
          </h4>
          <div sx={{ maxWidth: "400px", margin: "0 auto" }}>
            <Img
              title="Afterword piloting across the US"
              alt="Afterword piloting across the US"
              sx={{
                width: ["50%", "100%"],
                margin: ["0 auto"],
              }}
              fluid={data.map.childImageSharp.fluid}
            />
          </div>
        </div>
      </section>

      <section id="aboutus" className="aw-accent">
        <div className="aw-content">
          <h4 sx={{ fontWeight: 500, fontSize: "24px", textAlign: "center" }}>
            About Us
          </h4>

          <p>
            We're Effie and Zack. We started our careers working in tech at
            companies like Google, Shopify, and Workday.
          </p>
          <p>
            We started Afterword after Effie’s dad died in 2018 when she planned
            her first funeral. She found it difficult to find the information
            that she needed. Zack experienced loss when his mentor died suddenly
            and he didn’t know where to find support.
          </p>
          <p>
            Our main motivation is to help families through the worst of times.
            We've helped hundreds of families honor & remember their loved ones
            during the pandemic through virtual and livestream memorials. Now
            Afterword helps families by giving them the information they need to
            make the important decisions around funeral planning & grief
            resources.
          </p>
          <p>
            We don't favor one funeral home over another. Showing families all
            of their options is important to us so we don't charge funeral homes
            to appear on our site. We only make money from funeral homes when
            families plan their service through our platform.
          </p>
          <p>
            Afterword recommends personalized grief support from a national
            database of 1,000+ resources. All resources are vetted by our team
            and change based on feedback from the families that use our
            platform.
          </p>
          {/* <p>Afterword's purpose is to help families find meaning after losing someone. And it's our mission to help families make important decisions.</p> */}
          {/* <p>
            Our team and advisors include global pioneers in palliative
            care, emerging leaders in the next generation of the funeral
            industry, and designers & engineers from some of Silicon Valley's best companies.
          </p> */}
          <p>
            Our dedication to this work is deeply personal. If you have any
            questions, please{" "}
            <a href="mailto:contact@afterword.com" target="_blank">
              email us
            </a>
            .
          </p>

          <Grid columns={["1fr", "1fr 1fr"]}>
            <div>
              <div
                sx={{
                  margin: "0 auto",
                  width: ["250px", "200px", "250px", "300px"],
                  textAlign: "center",
                }}
              >
                <Img
                  title="Effie Anolik"
                  alt="illustration of Effie Anolik"
                  fluid={data.effieImage.childImageSharp.fluid}
                />
              </div>
              <p sx={{ textAlign: "center" }}>
                <b>Effie Anolik</b>
              </p>
            </div>

            <div>
              <div
                sx={{
                  margin: "0 auto",
                  width: ["250px", "200px", "250px", "300px"],
                  textAlign: "center",
                }}
              >
                <Img
                  title="Zack Moy"
                  alt="illustration of Zack Moy"
                  fluid={data.zackImage.childImageSharp.fluid}
                />
              </div>
              <p sx={{ textAlign: "center" }}>
                <b>Zack Moy</b>
              </p>
            </div>
          </Grid>
        </div>
      </section>

      <section className="" id="form">
        <div className="aw-content" sx={{ textAlign: "center" }}>
          <h4 sx={{ fontWeight: 500, fontSize: "24px" }}>
            Interested in signing up your hospital?
          </h4>

          <div sx={{ maxWidth: "500px", margin: "0 auto" }}>
            <BereavementContact />
          </div>

          <p sx={{ marginTop: "36px" }}>
            or email your Bereavement Guide to{" "}
            <a href="mailto:hospitals@afterword.com" target="_blank">
              hospitals@afterword.com
            </a>
          </p>
        </div>
      </section>

      <HowItWorksWhatNow id="howitworks" />

      <Faqs
        id="faqs"
        faqsTitle="FAQS"
        variant="accordion"
        hideCalendly
        questions={BEREAVEMENT_GUIDE_QUESTIONS}
      />

      <Testimonials id="testimonials" reviews={NonVirtualTestimonialReviews} />

      <CalendlySection
        pageName="free-bereavement-tools"
        headerOverride="Have questions? Schedule a training session with us"
        buttonOverride="Schedule Training"
        buttonVariant={"blueButton"}
        calendlyType={CalendlyEventTypes.HOSPITAL_BEREAVEMENT}
      />
    </Layout>
  );
};

export default BereavementPage;

export const pageQuery = graphql`
  query {
    home: file(relativePath: { regex: "/header.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tool1: file(relativePath: { regex: "/tool1.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tool2: file(relativePath: { regex: "/tool2.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tool3: file(relativePath: { regex: "/tool3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tool4: file(relativePath: { regex: "/tool4.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    map: file(relativePath: { regex: "/map.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effieImage: file(relativePath: { regex: "/about-effie.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    zackImage: file(relativePath: { regex: "/about-zack.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
